import React from 'react';
import { Layout } from '../components/layout';
import { Hero } from '@/components/layout/header/hero';
import { StaticImage } from 'gatsby-plugin-image';
import { Divider } from '@/components/ui/Divider';

export default function ChiSiamo() {
  const pageTitle = 'Chi Siamo';

  const Banner = () => (
    <StaticImage
      src="../images/banners/chisiamo.png"
      alt={pageTitle}
      quality={100}
    />
  );

  return (
    <Layout title={pageTitle}>
      <Hero title={pageTitle} banner={<Banner />} />
      <div style={{ maxWidth: '1280px' }} className="container mt-5 mb-5">
        <Divider size={3} />
        <div className="row">
          <div className="col-12 text-center text-primary">
            <div>
              <span className="fw-bold font-rubik">Vicenza Parcheggi</span> è
              uno dei brand della GPS Global Parking Solutions S.p.A che si
              occupa della gestione della sosta su strada e in struttura per la
              Città di Vicenza. – Vicenza Parcheggi, nasce a fronte
              dell’aggiudicazione da parte di{' '}
              <span className="fw-bold font-rubik">GPS S.p.A.</span> della
              concessione per la gestione dei parcheggi a pagamento, senza
              custodia, in superficie e in struttura siti sul territorio
              comunale di Vicenza, oltre il servizio di gestione dei Bicipark.
            </div>
            <br />
            <div>
              <span className="fw-bold font-rubik">GPS S.p.A.</span>, società
              operante nel settore della gestione di aree ed edifici destinati
              alla sosta su tutto il territorio nazionale, la quale fa parte
              del&nbsp;
              <span className="fw-bold font-rubik">Gruppo FINAL,</span> holding
              operativa di partecipazioni industriali della Famiglia Lodetti
              Alliata, operante nel settore industriale dei metalli già dai
              primi anni del Novecento e concentratasi dal 2004 nello sviluppo
              di concessioni di sosta in realtà cittadine di grande rilievo
              quali Cremona, Pavia, Piacenza, Reggio Emilia, Genova,
              Alessandria, Caltanissetta.
            </div>
            <br />
            <div>
              Il gruppo opera oggi, nell’ambito di municipalità medio/grandi,
              quale operatore unico e concessionario per la gestione della sosta
              e della mobilità.
            </div>
          </div>
        </div>
        <Divider size={5} />
      </div>
    </Layout>
  );
}
